body {
}

* {
	box-sizing: border-box;
}

.column {
	float: left;
	width: 33.33%;
	padding: 10px;
	height: auto; 
}


.row:after {
	content: "";
	display: table;
	clear: both;
}

.footer {
	padding-top: 20px;
	background-color: #ffffff;
	/*border-top: 6px solid #007298;*/
	/*padding: 5px 20px 25px 20px;*/
	color: #333;
	font-size: 11px;
	min-height: 195px;
	/*min-width: 950px;*/
}

.footer__inner {
	padding: 10px 25px;
	text-align: center;
}

.footer_left {
	float: left;
	padding-top: 0;
	width: 33.33333%;
}

.footer_center {
	float: left;
	width: 33.33333%;
	text-align: center;
}

.footer_right {
	float: left;
	width: 33.33333%;
	text-align: right;
}

.Whitebox {
	margin: 0 auto;
	border: 2px solid #d3d3d3;
	color: #ffffff;
	width: 280px;
	padding: 5px 10px;
	left: 10px;
	font-size: 13px;
}

.hag-logo {
	width: 126px;
	height: 40px;
}

.footerPoweredBy {
	padding-top: 5px;
	margin: 0;
	font-weight: 400;
	font-size: 11px;
	color: #D3D3D3;
	font-family: 'Lato', sans-serif;
	line-height: 1.428571429;
}

.footerCopyright {
	color: #D3D3D3;
	font-size: 11px;
	margin-top: 0px;
}

.footerLeft {
	margin-left: 10px;
	width: 275px;
	font-size: 12px;
	padding-top: 5px;
	color: #ffffff;
	line-height: 1.428571429;
}

	.footerLeft a {
		color: #ffffff;
		text-decoration: none;
	}

.footerLeft_Text {
	border: 2px solid #d3d3d3;
	color: #ffffff;
	width: 200px;
	padding: 5px;
	margin-left: 11px;
	font-size: 11px;
	text-align: center;
	white-space: nowrap;
	line-height: 1.428571429;
}

.footercode {
	color: #ffffff;
	width: 275px;
	margin-left: 10px;
	font-size: 12px;
	line-height: 1.428571429;
}

.footer_capri {
	font-size: 11px;
	border-collapse: initial;
	color: #333;
	margin:10px;
}

.footer_capr_sign4compliance_span{
	margin-left:5px;
}

.footer_capr_sign4compliance_div{
	margin: 0px 0px 0px 0px;
}

.footer_managedby {
	display: flex; /* Use flexbox for layout */
	flex-direction: column; /* Stack elements vertically */
	align-items: center; /* Ensure content is horizontally centered */
	justify-content: center; /* Ensure content is vertically centered if needed */
	width: 100%; /* Ensure it takes up full width of its container */
	box-sizing: border-box; /* Avoid overflow from padding/margin */
}

.transcendLogo {
	position: relative;
	left: 0px;
}

.footer .row {
	display: flex;
	justify-content: space-between; /* Distribute the columns evenly */
	align-items: flex-start; /* Align the items to the top */
	width: 100%;
	box-sizing: border-box; /* Ensures padding and borders are included in the width */
}

.column1, .column3 {
	flex: 0 1 250px; /* Fix the width of the left and right columns */
}

.column2 {
	flex: 1; /* The center column takes up the remaining space */
	text-align: center; /* Center-align the content in the center column */
}

.column1 img, .column3 img {
	max-width: 100%; /* Ensures images do not overflow */
}

/* Optional: Additional styling for the footer section for alignment */
.footer_capri, .footer_managedby {
	text-align: left;
}

	.footer_capri div, .footer_managedby div {
		/*margin-bottom: 10px;*/
	}


@media only screen and (device-width : 375px) and (device-height : 667px) and (-webkit-device-pixel-ratio : 2), screen and (min-width: 360px) and (max-width: 767px), screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3), screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3), screen and (device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 3), screen and (max-device-width: 640px), screen and (max-device-width: 667px) and (max-width: 480px), screen and (min-device-width : 375px) and (max-device-width : 667px) {
	.column {
		width: 100%;
		padding: 10px 25px;
		text-align: center;
	}

	.row {
		flex-direction: column; /* Stack columns vertically on small screens */
		align-items: center; /* Center align all sections */
	}

	.column1, .column2, .column3 {
		flex: 1 1 100%; /* Each column takes up the full width */
		text-align: center; /* Ensure content is centered */
	}

		/* Optional: Reduce image sizes for smaller screens */
		.column1 img, .column3 img {
			/*width: 80%;*/ /* Adjust image size for smaller screens */
			max-width: 200px; /* Keep image width capped */
			margin: 0 auto; /* Center the image */
		}

	.footer_capri {
		text-align: center;
		margin-left: 20px;
	}

	.Whitebox {
		margin: 0px;
	}

	.footer_managedby {
		margin-left: 0; /* Remove any unwanted margin-left */
		margin-right: 0; /* Remove any unwanted margin-right */
		padding: 0; /* Ensure no additional padding */
		text-align: center; /* Explicitly make sure text is centered */
	}

	.footer {
		height:400px;
	}
	.column1, .column2, .column3 {
		flex: 1 1 100%; /* Each column takes up the full width */
		text-align: center; /* Ensure content is centered */
	}
}