@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700,400italic);

h3 {
    color: #003763;
    font-size: 20px;
    margin: 0 0 5px 0;
    margin-top: 0;
    font-weight: 600;
    text-align: left;
    font-family: 'Lato', sans-serif;
}

h4 {
    font-size: 16px !important;
    font-weight: 400;
    margin: 0 0 5px 0;
    font-family: 'Lato', sans-serif;
}

/* #######################
##### GENERIC STYLES ##### 
######################## */

*,
*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    font-weight: 400;
    color: #454545;
    line-height: 1.428571429;
    /*background-color: #44587a;*/
    background: #FFF;
}

.clearall {
    clear: both;
    height: 0px;
}

#top_bar {
    height: 37px;
    background: #031a40;
}

.ImgColumn {
    width: 33%;
}

.img_BioCryst {
    float: left;
    position: relative;
}

.img_Orladeyo {
    text-align: center;
    position: relative;
}

.img_Empower {
    float: right;
    position: relative;
}

.img-header-logo {
    width: auto;
    height: 60px;
}

.no_banner {
    width: 100%;
    background: #FFF;
    padding-bottom: 40px;
    /*border-top: 1px solid #031a40;*/
}

.wrapper {
    width: 950px;
    margin: 0 auto;
}

.topmenu {
    text-align: right;
    padding-top: 4px;
    color: #FFFFFF;
    text-align: center;
}

    .topmenu a {
        color: #FFF;
        font-size: 13px;
        margin-left: 10px;
    }

#header {
    padding-top: 20px;
}

#bg_logo {
    width: 372px;
    height: 59px;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -470px;
}

#s4c_logo {
    width: 400px;
    float: left;
    padding: 3px 0 0 2px;
}

#nav {
    width: 50%;
    float: right;
    text-align: right;
    margin-top: 45px;
}

    #nav a:link, #nav a:visited {
        color: #44587a;
        font-size: 16px;
        background: #FFF;
        font-weight: 700;
        text-decoration: none;
        display: inline-block;
        padding: 22px 10px 15px;
        border-bottom: 5px solid #FFF;
    }

    #nav a:hover, #nav a:active {
        text-decoration: underline;
    }

#marquis {
    padding: 0 0 5px 2px;
}

    #marquis h1 {
        margin-top: 5px;
    }

h1 {
    color: #44587a;
    font-size: 2.5em;
    line-height: 0.9em;
    font-weight: 300;
}

    h1 span {
        color: #999;
        font-size: 16px;
        font-weight: 400;
    }

h2 {
    color: #000000;
    font-size: 1.5em;
    font-weight: 600;
}

p, ul, ol {
    font-size: 14px;
}

ul {
    padding-left: 30px;
    font-weight: 700;
}

.normal {
    font-weight: 400;
}

.columns {
    margin: 50px 0 60px;
}

.three_col {
    width: 29%;
    float: left;
    margin-right: 60px;
}

.last {
    margin-right: 0;
}

a {
    color: #0366d6;
}

code {
    color: #E01A76;
}

.required {
    color: red;
}

.errormessage {
    font-size: 12px;
    color: red;
    padding-left: 4px;
}

.greyTextCheckBox {
    padding-left: 10px;
    color: darkgrey;
}

.greyText {
    color: darkgrey;
}

.fieldlabel1 {
    font-size: 12px;
    color: #666666;
    border-color: black !important;
}

.fieldcontrol1 {
    width: 200px;
    border-color: black !important;
}

.k-textbox {
    border-color: black !important;
}

.fieldcontrol2 {
    width: 175px;
    border-color: black !important;
}

.fieldcontrolguest {
    width: 130px;
    border-color: black !important;
}

.Register-Container {
    width: 950px;
    margin: 0 auto;
    padding: 10px;
    line-height: 1.428571429;
}

.Register-Wrapper {
    color: #666666;
    background-color: #F9F9F9;
    font-size: 12px;
    font-family: "Open Sans", sans-serif;
    /*    border-right: 1px solid #D6D6D6;
    border-bottom: 1px solid #D6D6D6;*/
}

.row {
    margin-right: 0px !important;
    margin-left: 0px !important;
}

.divAdditionalRegistrants {
    float: left;
    padding: 5px;
    margin-left: 6px;
}

.divBorderPadding {
    padding: 20px 90px 0px 80px;
}

.borderText {
    border: 3px solid black;
    text-align: center;
}

.popUpDivBorderPadding {
    width: 100%;
    padding: 0px 15px 0px 10px;
}

.pBottom {
    margin-bottom : 10px;
}

.divtblguest {
    float: left;
}

.h2Title {
    color: #000000;
    font-size: 1.5em;
    font-weight: bold;
    padding-top: 10px;
}

.personalInformation {
    float: left;
    width: 30%;
    padding: 5px;
    margin-left: 10px;
}

.personalInformation_degree {
    float: left;
    width: 30%;
    padding: 5px;
    margin-left: 10px;
}

.contactInformation {
    float: left;
    width: 30%;
    padding: 5px;
    margin-left: 10px;
}

.contactInformation_Affiliation {
    float: left;
    width: 46%;
    padding: 5px;
    margin-left: 20px;
}

.btn-primary {
    color: #003b4a;
    background-color: #4aa39e;
    border-color: #4aa39e;
}

.btn-secondary {
    background-color: #56c2b7;
    border: solid 1px #56c2b7;
}

    .btn-primary:hover, .btn-primary:active, .btn-primary:focus {
        color: #003b4a;
        background-color: #4aa39e;
        border-color: #4aa39e;
    }

    .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
        color: #003b4a;
        background-color: #4aa39e;
        border-color: #4aa39e;
    }

.btn-gray {
    color: #fff;
    background-color: #AAAAAA;
}

.ConfirmationPopup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background: rgba(0,0,0,0.2);
}

.Confirm_Dialog {
    padding: 0 0px 20px 0px;
    text-align: center;
    border: 3px solid #4aa39e;
    width: 350px;
    max-width: 100%;
    margin: auto;
    position: relative;
    top: 30%;
    background: #fff;
}

.Confirm_Dialog_Title {
    background-color: #4aa39e;
    padding: 5px;
    color: #fff;
    font-weight: 700;
    font-size: 15px;
}

.closebox {
    cursor: pointer;
    padding: 8px 12px;
    background: #4aa39e;
    color: #fff;
    float: right;
    position: relative;
    top: -5px;
    font-size: 11px;
    left: 7px;
    text-decoration: none;
}

.TextDeclare {
    padding-top: 10px;
    font-weight: 400;
    font-size: 14px;
}

.BtnRow {
    padding: 20px 0px 0px 0px;
}

    .BtnRow a {
        display: inline-block;
        color: #fff;
        padding: 5px;
        width: 175px;
        border-radius: 4px;
        background: #4aa39e;
        margin: 0px 5px;
        font-weight: 400;
        font-size: 15px;
        text-decoration: none !important;
        cursor: pointer;
    }

        .BtnRow a:hover, .BtnRow a:active, .BtnRow a:focus {
            background: #4aa39e;
            text-decoration: none;
            color: #003b4a;
        }

.statusForm {
    border: 2px #003763 solid;
    border-radius: 15px;
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
}

.statusActionControls {
    width: 100%;
}

.statusAction {
    float: left;
}

.statusPersonalInformation {
    float: left;
    width: 25%;
    padding: 5px;
}

.fieldlabel {
    font-size: 10px;
    color: #757575;
}

.statusContactInformation_Longfield {
    float: left;
    width: 38%;
    padding: 5px;
}

.statusContactInformation {
    float: left;
    width: 25%;
    padding: 5px;
}

.statusActionButtons {
    width: 20%;
}

.linkbutton {
    color: #003b4a !important;
    background-color: #4aa39e;
    font-size: 12px;
    cursor: pointer;
    font-weight: 700;
    display: block;
    padding: 10px;
    border-radius: 4px 4px 4px 4px;
    margin-top: 4px;
    text-decoration: none;
}

.link-button5 {
    background-attachment: scroll;
    background-clip: border-box;
    background-color: #4aa39e;
    background-image: none;
    background-origin: padding-box;
    background-position: 0% 0%;
    background-position-x: 0%;
    background-position-y: 0%;
    background-repeat: repeat;
    background-size: auto auto;
    border-bottom-color: rgb(141, 11, 86);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-image-outset: 0;
    border-image-repeat: stretch stretch;
    border-image-slice: 100%;
    border-image-source: none;
    border-image-width: 1;
    border-left-color: rgb(141, 11, 86);
    border-left-style: solid;
    border-left-width: 1px;
    border-right-color: rgb(141, 11, 86);
    border-right-style: solid;
    border-right-width: 1px;
    border-top-color: rgb(141, 11, 86);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-top-style: solid;
    border-top-width: 1px;
    box-sizing: border-box;
    color: rgb(255, 255, 255);
    cursor: pointer;
    display: block;
    float: right;
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 5px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 5px;
    padding-bottom: 3px;
    padding-left: 7px;
    padding-right: 7px;
    padding-top: 3px;
    position: relative;
    text-decoration: none;
}

.link-button6 {
    border: 0px !important;
    background-attachment: scroll;
    background-clip: border-box;
    background-color: #56c2b7;
    background-image: none;
    background-origin: padding-box;
    background-position: 0% 0%;
    background-position-x: 0%;
    background-position-y: 0%;
    background-repeat: repeat;
    background-size: auto auto;
    border-radius: 35px;
    /*border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;*/
    box-sizing: border-box;
    color: #003b4a !important;
    display: inline-block;
    font-family: "Open Sans", sans-serif;
    font-size: 15.6px;
    font-weight: 700;
    line-height: 22.2833px;
    padding: 8px 32px;
    /*padding-bottom: 15px;
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 15px;*/
    text-decoration: none;
    text-decoration-color: rgb(255, 255, 255);
    text-decoration-line: none;
    text-decoration-style: solid;
}

    .link-button:hover, .link-button1:hover, .link-button2:hover, .link-button4:hover, .link-button6:hover, .link-button7:hover {
        text-decoration: none;
    }

.link-button6-grey {
    border: 0px !important;
    background-attachment: scroll;
    background-clip: border-box;
    background-color: #8A8A8A;
    background-image: none;
    background-origin: padding-box;
    background-position: 0% 0%;
    background-position-x: 0%;
    background-position-y: 0%;
    background-repeat: repeat;
    background-size: auto auto;
    border-radius: 35px;
    /*border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;*/
    box-sizing: border-box;
    color: rgb(255, 255, 255) !important;
    display: inline-block;
    font-family: "Open Sans", sans-serif;
    font-size: 15.6px;
    font-weight: 700;
    line-height: 22.2833px;
    outline-color: rgb(255, 255, 255);
    outline-style: dotted;
    outline-width: 1px;
    padding: 8px 32px;
    /*padding-bottom: 15px;
    padding-left: 32px;
    padding-right: 32px;
    padding-top: 15px;*/
    text-decoration: none;
    text-decoration-color: rgb(255, 255, 255);
    text-decoration-line: none;
    text-decoration-style: solid;
    text-shadow: rgb(85, 85, 85) 1px 1px 0px;
}

    .link-button6-grey:hover {
/*        background: rgb(170, 170, 170);
*/        text-decoration: none;
    }

select, input[type="text"], input[type="email"] {
    background-color: #FFFFFF;
    background-image: none;
    border: 1px solid #CCCCCC;
    border-radius: 0px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
    color: #555555;
    display: block;
    font-size: 12px;
    height: 22px;
    line-height: 1.42857;
    padding: 2px 6px;
    transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
    vertical-align: middle;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.labelcheckboxes {
    padding-left: 8px;
    vertical-align: text-top;
}

.wrap {
    width: 100%;
    margin: 0 auto;
    text-align: center;
}

.hero-left {
    width: 40%;
    min-width: 400px;
    float: left;
    background: #ffffff;
}

    .hero-left .inner {
        padding: 30px 18px 0;
    }

.hero h2 {
    color: black;
    font-size: 14px;
    margin: 0 0 5px 0;
}

.hero-right {
    width: 60%;
    min-width: 300px;
    text-align: center;
    display: table-cell;
    height: 400px;
    padding: 0px 20px;
}

.header-title {
    height: 80px;
    text-align: left;
}

.BioCrystdiv {
    float: left;
    width: 300px;
    position: relative;
}

.BioCrystImg {
    width: auto;
    height: 80px;
    padding-left: 10px;
}

.Orladeyodiv {
    float: right;
    width: 350px;
}

.OrladeyoImg {
    width: auto;
    float: right;
    height: 80px;
    padding-left: 10px;
}

ul.main-nav {
    padding-left: 0;
    border-top: 1px solid #CCC;
    margin: 0;
    text-align: right;
    font-weight: 300;
    background-color: #4aa39e;
    height: 25px;
}


.container {
    width: 98%;
    margin: 0 auto;
    /*min-width: 600px;*/
    min-width: auto;
}


.Confirmation-Container {
    background-color: #fff;
    min-height: 500px;
    line-height: 1.428571429;
    font-family: "Open Sans", sans-serif;
}

.Confirmation-fields {
    height: 30px !important;
    font-size: 13px !important;
    width: 200px;
    border-color: black !important;
}

* {
    box-sizing: border-box;
}

.regColumn {
    float: left;
    width: 33.33%;
    padding: 10px;
}

.regColumn2 {
    float: left;
    width: 15%;
    padding: 10px;
}

.regContainerWithISI {
    /*height: 740px;*/
    /*height: 1315px;*/
    height: 1265px;
}

.regContainerWithoutISI {
    height: auto;
}

.row:after {
    content: "";
    display: table;
    clear: both;
}

.eventinfoColumn {
    float: left;
    width: 33.33%;
    color: #ffffff;
    font-size: 14px;
    background-color: #4aa39e;
    padding: 5px 10px 5px 4px;
    border: solid 2px white;
    padding-left: 20px;
}

.eventinfoValueColumn {
    float: left;
    /*width: 33.33%;*/
    font-size: 14px;
    color: black;
    padding: 0px 10px 10px 20px;
    /*white-space: pre-wrap;*/
}

.confirmationContents {
    width: 950px;
    margin: 0 auto;
}

.receiptColumn {
    float: left;
    padding: 10px;
}

.receiptColumnLeft {
    width: 80%;
}

.receiptColumnRight {
    width: 20%;
}

.receiptRegistrantColumn {
    width: 25%;
}

.receiptButtonsColumn {
    /*width: 25%;*/
}

.receiptButtons{
    display:flex;
    margin-top:2px;
}

.receiptButtonsImage{
    margin: 12px 3px;
}

.receiptButtonslink{
    width: 80px;
}

.addGuestGridHeader{
    display:flex;
}

.textboxGuestTypeOther{
    margin-top:2px;
}

.headerTitle {
    color: #FFFFFF;
    text-align: center;
    font-size: 18px;
    padding-top: 5px;
    width: 90%;
    float: left;
}

.headerHome {
    color: #FFFFFF;
    text-align: center;
    font-size: 16px;
    padding-top: 5px;
    width: 10%;
    float: right
}

@media only screen and (device-width : 375px) and (device-height : 667px) and (-webkit-device-pixel-ratio : 2), screen and (min-width: 360px) and (max-width: 767px), screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3), screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3), screen and (device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 3), screen and (max-device-width: 640px), screen and (max-device-width: 667px) and (max-width: 480px), screen and (min-device-width : 375px) and (max-device-width : 667px) {
    .regColumn {
        width: 100%;
        text-align: left;
    }

    .regColumn2 {
        width: 100%;
        text-align: left;
    }

    .fieldcontrol1 {
        width: 100%;
    }

    .fieldcontrol2 {
        width: 100%;
    }

    .fieldcontrolguest {
        width: 100%;
    }

    .Register-Container {
        width: 100%;
    }

    .eventinfoColumn {
        font-size: 12px;
    }

    .eventinfoValueColumn {
        font-size: 12px;
    }

    .confirmationContents {
        width: 100%;
    }

    .receiptColumnLeft {
        width: 100%;
    }

    .receiptColumnRight {
        width: 100%;
    }

    .receiptRegistrantColumn {
        width: 100%;
    }

    .Confirmation-fields {
        width: 100%;
    }

    .headerTitle {
        text-align: left;
        font-size: 16px;
        padding-left: 5px;
        padding-top: 10px;
        width: 75%;
    }

    .headerHome {
        font-size: 14px;
        padding-right: 20px;
        padding-top: 10px;
        width: 20%;
    }

    .regContainerWithISI {
        height: auto;
    }

    #divReceiptColumn table tr > * {
        display: block;
    }

    #divReceiptColumn table tr {
        display: table-cell;
        padding-right: 5px;
        padding-left: 5px;
    }

    .divAdditionalRegistrants {
        width: -webkit-fill-available;
    }

    .divtblguest {
       width: -webkit-fill-available;
    }
}
