body {
	font-family: AvenirNext,Arial,Helvetica,sans-serif;
}
#fixed-isi {
	position: fixed;
	top: 100%;
	left: 0;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	width: 100%;
	background-color: #fff;
	height: 100vh;
	overflow: hidden;
	padding-top: 0;
	z-index:1;
}

	#fixed-isi.loaded {
		-webkit-transition: top 1s;
		transition: top 1s
	}

	#fixed-isi.hide {
		opacity: 0;
		z-index: -1
	}

	#fixed-isi .banner {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 auto;
		flex: 0 0 auto;
		text-align: center;
		background: white;
		border-top: 1px solid rgba(0, 0, 0, 0.2); /* Border at top to simulate projection */
		box-shadow: 0 8px 10px rgba(0, 0, 0, 0.1); /* Optional: subtle shadow to enhance depth */
	}

		#fixed-isi .banner .container {
			padding-top: 4px;
			padding-bottom: 4px
		}

		#fixed-isi .banner h2 {
			position: relative;
			display: inline-block;
			font-size: 18px;
			margin-bottom: 0;
			/*color: #fff;*/
			/*color: #003b4a;*/
			color: #007298;
			text-align: center;
			padding-right: 48px;
			font-weight: 700;
		}
		#fixed-isi .banner .container .content h2 {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

			#fixed-isi .banner h2:after {
				content: "";
				position: absolute;
				top: 50%;
				right: 0;
				-webkit-transform: translateY(-50%);
				transform: translateY(-50%);
				width: 16px;
				height: 16px;
				/*background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14.512 14.511'%3E%3Cg fill='none' stroke='%23fff' stroke-linecap='round' stroke-width='2'%3E%3Cpath d='M1.001 7.256h12.512M7.256 1v12.511'/%3E%3C/g%3E%3C/svg%3E");*/
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14.512 14.511'%3E%3Cg fill='none' stroke='%23003B4A' stroke-linecap='round' stroke-width='2'%3E%3Cpath d='M1.001 7.256h12.512M7.256 1v12.511'/%3E%3C/g%3E%3C/svg%3E");
				background-size: contain;
				background-repeat: no-repeat
			}

	#fixed-isi.active .banner h2:after {
		-webkit-transform: translateY(-50%) rotate(45deg);
		transform: translateY(-50%) rotate(45deg)
	}

/*	#fixed-isi .banner h2:before {
		content: "Click to expand and close";
		top: 50%;
		left: 70%;
		-webkit-transform: translateY(-50%);
		width: 100%;
		font-size: 14px;
		position: absolute;
	}
*/
	#fixed-isi .container {
		/*overflow: auto;*/
		-ms-flex-preferred-size: 0;
		flex-basis: 0;
		-webkit-box-flex: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		padding-top: 12px;
		padding-bottom: 12px
	}

	#fixed-isi > .container {
		padding-bottom: 100vh
	}

@media screen and (max-width:639px) {
	#fixed-isi .banner .container .content {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex
	}

		#fixed-isi .banner .container .content h2 {
			font-size: 14px
		}
}

.isi-top-active {
	top: 0px !important;
	position: relative;
}

.isi-top-inactive {
	top: calc(100% - 32px) !important;
	position: relative;
}

.isi .content h3 {
	font-size: 18px;
	font-weight: bold;
	color: #007298;
}
.isi .content p {
	font-size: 14px;
	/*color: #003b4a;*/
	color: #404040;
}
.isi h3 {
	font-size: 18px;
	font-weight: bold
}

.isi .must-be-visible {
	margin-bottom: 8px
}

.isi p {
	margin-bottom: 8px
}

	.isi p:last-child {
		margin-bottom: 0
	}

.isi > .container {
	padding-right: 38px
}

.isi ul.styled > li {
	list-style: none;
	padding-left: 1.75rem
}

	.isi ul.styled > li:before {
		content: "";
		position: absolute;
		background-color: #007298;
		border-radius: 50%;
		width: .375em;
		height: .375em;
		top: .55em;
		left: .25em
	}

#interstitials, #exit-confirmation {
	display: none;
	width: 62.4%;
	max-width: 1198px;
	position: fixed;
	top: 100px;
	left: 50%;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
	border: 27px solid #f0f0f0;
	background-color: #007298;
	padding: 82px 42px 84px;
	z-index: 30
}

	#interstitials .close, #exit-confirmation .close {
		/*background: url("../images/close.png") no-repeat;*/
		background-size: contain;
		width: 30px;
		height: 30px;
		position: absolute;
		top: 30px;
		right: 65px
	}

	#interstitials h1, #interstitials h2, #exit-confirmation h1, #exit-confirmation h2 {
		color: #fff;
		text-align: center
	}

	#interstitials h1, #exit-confirmation h1 {
		font-size: 36px;
		margin-bottom: 64px
	}

	#interstitials h2, #exit-confirmation h2 {
		font-size: 24px;
		margin-bottom: 43px
	}

	#interstitials button.btn, #interstitials a.btn, #exit-confirmation button.btn, #exit-confirmation a.btn {
		text-decoration: none;
		display: inline-block;
		cursor: pointer
	}

	#interstitials .btn-container, #exit-confirmation .btn-container {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
		-webkit-box-pack: justify;
		-ms-flex-pack: justify;
		justify-content: space-between;
		width: 528px;
		max-width: 100%;
		margin: auto
	}

	#interstitials.active, #exit-confirmation.active {
		display: block
	}

@media screen and (max-width:1023px) {
	#interstitials, #exit-confirmation {
		padding: 2rem .75rem 1.5rem;
		width: calc(100% - 32px)
	}

		#interstitials .close, #exit-confirmation .close {
			width: 1rem;
			height: 1rem;
			top: 1rem;
			right: 1rem
		}
}

@media screen and (max-width:639px) {
	#interstitials, #exit-confirmation {
		top: 5%;
		left: 5%;
		-webkit-transform: none;
		transform: none;
		max-height: 90%;
		overflow-x: hidden;
		overflow-y: auto
	}

		#interstitials h1, #exit-confirmation h1 {
			font-size: 28px
		}

		#interstitials .btn-container, #exit-confirmation .btn-container {
			-webkit-box-orient: vertical;
			-webkit-box-direction: normal;
			-ms-flex-direction: column;
			flex-direction: column;
			-webkit-box-pack: center;
			-ms-flex-pack: center;
			justify-content: center;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center
		}

			#interstitials .btn-container .btn, #exit-confirmation .btn-container .btn {
				display: block
			}

				#interstitials .btn-container .btn:not(:first-child), #exit-confirmation .btn-container .btn:not(:first-child) {
					margin-top: 21px
				}
}

#exit-confirmation h1 {
	margin-bottom: 73px
}

/*.btn {
	border-radius: 41px;
	-webkit-box-shadow: 0 6px 8px 0 rgba(0,0,0,0.16);
	box-shadow: 0 6px 8px 0 rgba(0,0,0,0.16);
	padding: .75rem 2rem;
	font-weight: 500;
	font-size: 14px;
	line-height: 1.25;
	text-align: center;
	width: 200px
}*/


.inline-flex {
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex
}

.text-left {
	text-align: left
}

.bg-body {
	background-color: #1a80a3
}

.bg-body-dark {
	background-color: #0e7196
}

.rounded {
	border-radius: 20px
}

.mb-0 {
	margin-bottom: 0
}

.introduction {
	background-color: #fff;
	font-weight: 600;
	color: #007298;
	padding: 30px 0
}

	.introduction img.capsule {
		margin: 56px auto 46px;
		width: 120px
	}

	.introduction img.logo {
		margin: 32px auto 46px;
		width: 337px
	}

	.introduction p {
		margin-bottom: 10px;
		font-size: 24px;
		text-align: center;
		line-height: 1.36;
		font-weight: 600
	}

		.introduction p.subtitle {
			margin-bottom: 24px;
			font-weight: bold
		}

		.introduction p.footnote {
			color: #707070;
			text-align: right;
			font-size: 14px;
			font-weight: 400;
			margin-bottom: 0
		}

@media screen and (max-width:639px) {
	.introduction {
		padding-top: 1.5rem;
		padding-bottom: .75rem
	}

		.introduction .page-title {
			font-size: 24px;
			margin-bottom: 24px
		}

		.introduction img {
			margin-bottom: 36px
		}

		.introduction p {
			font-size: 14px
		}

			.introduction p.subtitle {
				font-size: 20px
			}

			.introduction p.footnote {
				font-size: 12px;
				margin-bottom: 0
			}
}

.next-steps {
	background-color: #007298;
	padding: 80px 0
}

	.next-steps .row {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		margin-right: -2rem;
		margin-left: -2rem
	}

		.next-steps .row > div {
			position: relative;
			width: 100%;
			min-height: 1px;
			padding-right: 1rem;
			padding-left: 1rem;
			-webkit-box-flex: 0;
			-ms-flex: 0 0 33.333%;
			flex: 0 0 33.333%;
			max-width: 33.333%
		}

	.next-steps .landing-card {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		background-color: #1a80a3;
		border-radius: 20px;
		padding: 3.5rem 2rem;
		color: #f0f0f0;
		text-align: center;
		height: 100%
	}

		.next-steps .landing-card > * {
			max-width: 600px;
			margin-left: auto;
			margin-right: auto
		}

		.next-steps .landing-card h2 {
			font-size: 24px;
			text-align: center
		}

		.next-steps .landing-card img {
			margin: 0 auto 32px
		}

		.next-steps .landing-card p {
			font-size: 14px;
			margin-bottom: 0
		}

		.next-steps .landing-card ul, .next-steps .landing-card ol {
			font-size: 14px;
			line-height: 1.38;
			margin-bottom: 12px;
			max-width: 100%
		}

			.next-steps .landing-card ul ul, .next-steps .landing-card ol ul {
				list-style: disc
			}

			.next-steps .landing-card ul a, .next-steps .landing-card ol a {
				word-break: break-word
			}

			.next-steps .landing-card ul li, .next-steps .landing-card ol li {
				margin-top: 12px
			}

		.next-steps .landing-card a {
			color: #56c2b7;
			text-decoration: none
		}

		.next-steps .landing-card .button-container {
			margin: auto auto 0;
			width: 200px;
			max-width: 100%
		}

		.next-steps .landing-card .button {
			display: inline-block;
			margin-top: 36px;
			background-color: #56c2b7;
			border: 0;
			cursor: pointer;
			border-radius: 41px;
			-webkit-box-shadow: 0 6px 8px 0 rgba(0,0,0,0.16);
			box-shadow: 0 6px 8px 0 rgba(0,0,0,0.16);
			color: #fff;
			padding: 10px 0;
			font-weight: 500;
			font-size: 14px;
			text-align: center;
			width: 100%
		}

		.next-steps .landing-card .phone-align {
			display: inline-table
		}

@media screen and (max-width:1023px) {
	.next-steps .row {
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center
	}

		.next-steps .row > div {
			-webkit-box-flex: 0;
			-ms-flex: 0 0 50%;
			flex: 0 0 50%;
			max-width: 50%
		}

			.next-steps .row > div:not(first-child) {
				margin-top: 1.5rem
			}
}

@media screen and (max-width:767px) {
	.next-steps .row > div {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		width: 100%;
		max-width: 100%
	}
}

@media screen and (max-width:639px) {
	.next-steps .button-container {
		width: 200px
	}

		.next-steps .button-container .button {
			font-size: 14px
		}
}

.isi {
	padding: 24px 0;
	background-color: #fff
}

	.isi h3 {
		font-size: 18px;
		font-weight: bold
	}

	.isi p:last-child {
		margin-bottom: 0
	}

.reference {
	background-color: #f4f4f4;
	color: #707070;
	padding: 25px 0
}

	.reference p {
		font-size: 12px;
		line-height: 17px
	}

		.reference p:last-child {
			margin-bottom: 0
		}

		.reference p span {
			font-weight: 600
		}

div.pop-up {
	display: none;
	position: fixed;
	top: 100px;
	left: 50%;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
	width: 62.4%;
	max-width: 1198px;
	height: 920px;
	max-height: calc(90% - 100px);
	overflow-x: hidden;
	overflow-y: auto;
	border: 56px solid #f0f0f0;
	background-color: #007298;
	padding: 56px;
	z-index: 30
}

	div.pop-up .pop-up-header {
		max-width: 720px;
		margin-left: auto;
		margin-right: auto
	}

	div.pop-up .pop-up-body {
		max-width: 600px;
		margin-left: auto;
		margin-right: auto
	}

	div.pop-up .close {
		/*background: url("../images/close.png") no-repeat;*/
		background-size: contain;
		width: 30px;
		height: 30px;
		position: absolute;
		top: 30px;
		right: 65px
	}

	div.pop-up.active {
		display: block
	}

@media screen and (max-width:1023px) {
	div.pop-up {
		padding: 1.5rem .75rem;
		width: calc(100% - 32px)
	}

		div.pop-up .close {
			width: 1rem;
			height: 1rem;
			top: 1rem;
			right: 1rem
		}
}

@media screen and (max-width:639px) {
	div.pop-up {
		border-width: 18px;
		top: 5%;
		height: 90%;
		max-height: 90%
	}
}

.lightbox.active {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	-webkit-filter: blur(30px) brightness(1.1);
	filter: blur(30px) brightness(1.1);
	z-index: 20
}

.form-page-content {
	padding: 2rem 0 4rem;
	background-color: #0e7196
}

	.form-page-content .container {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: row;
		flex-direction: row;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap
	}

		.form-page-content .container > * {
			min-width: 1px;
			min-height: 1px
		}

	.form-page-content .back-button {
		display: inline-block;
		text-decoration: none;
		border: solid #fff 1px;
		border-radius: 42px;
		padding: 10px 21px;
		color: #fff;
		background-color: transparent
	}

		.form-page-content .back-button:hover {
			background-color: #fff;
			color: #0e7196
		}

	.form-page-content .button-container {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 20%;
		flex: 0 0 20%;
		width: 20%;
		max-width: 20%
	}

	.form-page-content .main-container {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 60%;
		flex: 0 0 60%;
		width: 60%;
		max-width: 60%;
		padding: 2rem;
		background-color: #1a80a3;
		border-radius: 20px
	}

		.form-page-content .main-container .content {
			max-width: 590px;
			margin: 0 auto
		}

	.form-page-content .form-heading {
		color: #fff;
		font-size: 24px;
		text-align: center
	}

@media screen and (max-width:1023px) {
	.form-page-content .button-container, .form-page-content .main-container {
		-webkit-box-flex: 0;
		-ms-flex: 0 0 100%;
		flex: 0 0 100%;
		width: 100%;
		max-width: 100%
	}

	.form-page-content .main-container {
		margin-top: 2rem
	}
}

.page-home-link {
	margin: 2rem
}

	.page-home-link img {
		width: 200px
	}

@media only screen and (device-width : 375px) and (device-height : 667px) and (-webkit-device-pixel-ratio : 2), screen and (min-width: 360px) and (max-width: 767px), screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3), screen and (min-device-width: 414px) and (max-device-width: 736px) and (-webkit-min-device-pixel-ratio: 3), screen and (device-width: 360px) and (device-height: 640px) and (-webkit-device-pixel-ratio: 3), screen and (max-device-width: 640px), screen and (max-device-width: 667px) and (max-width: 480px), screen and (min-device-width : 375px) and (max-device-width : 667px) {
	#fixed-isi .banner .container .content h2 {
		font-size: 12px;
	}
	#fixed-isi .content {
		width:100%;
	}

	#fixed-isi .content {
		/*overflow: auto;*/
	}

	#fixed-isi .banner h2:before {
		top: 121%;
		left: 0%;
	}

	#fixed-isi .banner h2:after {
		top: 121%;
		left: 15%;
	}

	#fixed-isi .banner .container {
		padding-top: 4px;
		padding-bottom: 20px
	}

	.isi-top-inactive {
		top: calc(100% - 47px) !important;
		position: relative;
	}

}