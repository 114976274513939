/* Scope all styles within .new-home container */
/* Menu Container */
.new-home .menu {
    background-color: #fff;
    color: #333;
    padding: 10px 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap; /* Allows content to wrap on smaller screens */
    border-bottom: 1px solid #ddd;
}


    .new-home .menu .img-header-logo {
        max-width: 100%; /* Ensure images do not overflow */
        /*height: auto;*/ /* Maintain aspect ratio */
    }

        .new-home .menu .nav-links {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            width: 100%;
        }

        .new-home .menu a {
            color: #333;
            text-decoration: none;
            margin: 0 15px;
        }

            .new-home .menu a:hover {
                text-decoration: underline;
            }

    .new-home .container {
/*        max-width: 1200px;
*/        margin: auto;
        /*padding: 0px 40px;*/
        background: #fff;
/*        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);*/
    }

    .new-home .banner {
        background: url('https://orladeyo.com/img/home-hero.jpg?2024') no-repeat center center;
        background-size: cover;
        height: 300px;
    }

    .new-home .banner-container {
        display:block;
        position: relative;
        width: 100%;
        /*max-width: 1200px;*/ /* Adjust width as needed */
        /*margin: 0 auto;*/
    }

/* The banner image */
    .new-home .banner-image {
        position: relative;
        width: 100%;
        /*height: 500px;*/ /* Adjust based on your image size */
    }

        .new-home .banner-image::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 20px; /* Adjust height of the fade effect */
            background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
            pointer-events: none; /* Prevent the overlay from interfering with interactions */
        }

        .new-home .banner-image img {
            width: 100%;
            height: 100%;
            object-fit: cover; /* Ensures the image covers the container fully */
        }

/* Overlay text styling */
    .new-home .overlay-text {
        position: absolute;
        transform: translateY(-50%); /* Vertically center the text */
        color: white;
        /*background-color: rgba(0, 0, 0, 0.5);*/ /* Optional semi-transparent background */
        /*padding: 20px;*/
        width: 40%; /* Adjust width to control the text box size */
    }

.new-home .overlay-text-1 {
    top: 10%;
    left: 25%;
    text-align: center;
    width: 27%;
    color: #007298;
}
.new-home .overlay-text-2 {
    top: 70%;
    left: 34%;
    font-size: 8px !important;
    color:#333;
}

.new-home .overlay-text-2 p {
    font-size: 10px !important;
    color: #333;
}

.new-home .overlay-text-3 {
    top: 82%;
    left: 20%;
    text-align: center;
}

    .new-home .overlay-text-3 p {
        font-size: 12px !important;
        color: #333;
    }

.new-home .overlay-text-4 {
    top: 81%;
    left: 74%;
    width: 14%;
}

    .new-home .overlay-text-4 p {
        font-size: 12px !important;
        font-weight: bold;
    }

.new-home .overlay-text h2, .overlay-text h3 {
    margin: 0;
    font-size: 13px;
    font-weight: bold;
}

    .new-home .header {
        text-align: center;
        padding: 20px;
        background-color: #ffffff;
        color: #fff;
    }

.new-home .right-header h1 {
    text-align: left;
    background-color: #ffffff;
    color: #174a7c;
    font-size: 13px;
    font-weight: bold;
}

    .new-home .header h1 {
        margin: 0;
        font-size: 28px;
        text-transform: uppercase;
    }

.new-home .content {
    /*display: flex;*/
    margin-top: 10px;
    width: 100%;
}


    .new-home .filter-sub {
        color: #97779B;
        font-weight: 700;
    }



.new-home .programs-row {
    display: flex; /* Default layout: Flexbox */
    /*justify-content: space-between;*/
    margin-bottom: 20px;
    flex-wrap: wrap; /* Allow wrapping to prevent overflow */
    gap: 16px; /* Space between cards */
    /*padding-top: 20px;*/
    width: 100%; /* Prevent container overflow */
    box-sizing: border-box; /* Include padding in width */
    overflow-x: hidden; /* Prevent horizontal scrolling */
}



    .new-home .program {
        width: 32%;
        background: #f9f9f9;
        padding: 15px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        text-align: center;
    }

        .new-home .program img {
            max-width: 100%;
            height: auto;
        }

        .new-home .program h3 {
            font-size: 16px;
            margin: 10px 0;
            color: black;
            text-align:center;
        }

        .new-home .program p {
            font-size: 14px;
            color: #555;
        }

        .new-home .program button {
            background: #56c2b7;
            color: #fff;
            padding: 10px 15px;
            border: none;
            border-radius: 4px;
            cursor: pointer;
            margin-top: 10px;
        }

            .new-home .program button:hover {
                background: #56c2b7;
            }

    .new-home .cta {
        text-align: center;
        margin: 20px 0;
    }

        .new-home .cta a {
            display: inline-block;
            background: #56c2b7;
            color: #003b4a;
            padding: 8px 25px;
            text-decoration: none;
            border-radius: 35px;
            font-size: 13px;
            font-weight: bold;
        }

            .new-home .cta a:hover {
                background: #56c2b7;
            }

.new-home .moreInfo {
    text-align: center;
    color: #174a7c;
}

.new-home .images-row {
    display: flex;
    justify-content: space-between; /* Distribute the images and text */
    align-items: center; /* Vertically align the content */
    width: 100%;
    box-sizing: border-box;
}

    .new-home .images-row .left-image,
    .new-home .images-row .right-image {
        /*width: 48%;*/
        /*flex: 1;*/ /* Fixed width for left and right images */
    }

    .new-home .right-image
    {
        text-align:right;
    }

    .new-home .images-row img {
        max-width: 100%;
        /*   height: auto;*/
    }

.center-text {
    flex: 1; /* Takes up all the available space between the images */
    font-size: 13px; /* Adjust the font size as needed */
    text-align: center; /* Center the text inside the center div */
}

    .center-text a {
        color: #007bff; /* Link color */
        text-decoration: none;
    }

        .center-text a:hover {
            text-decoration: underline; /* Optional: adds underline on hover */
        }

.pagination-controls {
    display: none;
    margin-top: 20px;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
}

.pagination-numbers {
    display: none;
    gap: 10px
}

.pagination-arrows {
    display: flex;
    gap: 10px
}

    .pagination-arrows button {
        padding: 6px 12px;
        border-radius: 4px;
        border: 1px solid #ccc;
        background-color: #f0f0f0;
        color: #333;
    }

.pagination-number {
    background-color: #007298;
    color: white;
    border: none;
    padding: 8px 12px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 14px;
    transition: background-color 0.3s;
}

    .pagination-number:hover {
        background-color: #005a6c; /* Darker shade for hover */
    }

    .pagination-number.active {
        background-color: #007298; /* Different color for active page */
        font-weight: bold;
        cursor: default;
    }


.capsule-list {
    list-style: none; /* Remove default bullets */
    padding-left: 20px;
    font-weight:normal;
}

    .capsule-list li {
        position: relative;
        padding-left: 30px; /* Space for the custom bullet */
        margin-bottom: 10px;
    }

        .capsule-list li::before {
            content: ''; /* Empty content for the custom bullet */
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 15px;
            height: 7px;
            background-color: #007298; /* Change color for each bullet */
            border-radius: 10px; /* Makes the bullet a capsule */
        }

        /* Customize bullet colors */
        .capsule-list li:nth-child(2)::before {
            background-color: purple;
        }

        .capsule-list li:nth-child(3)::before {
            background-color: lawngreen;
        }
        .capsule-list li:nth-child(4)::before {
            background-color: deepskyblue;
        }
        .capsule-list li:nth-child(5)::before {
            background-color: lightcoral;
        }
        .capsule-list li:nth-child(6)::before {
            background-color: #FFD300;
        }

.capsule-item {
    display: flex;
    align-items: center;
    margin-bottom: 10px; /* Adjust the spacing between items */
    width: 70%;
}

    .capsule-item img {
        width: 40px; /* Adjust the icon size */
        height: 40px; /* Adjust the icon size */
        margin-right: 10px; /* Space between icon and text */
    }

    .capsule-item span {
        width: 100%;
        font-size: 13px; /* Adjust the text size */
        border-radius: 35px;
        padding: 10px;
    }


.capsule-item-1 {
    border: 1px solid #00739B;
}

.capsule-item-2 {
    border: 1px solid #56C2B8;
}

.capsule-item-3 {
    border: 1px solid #2c9942;
}

.capsule-item-4 {
    border: 1px solid #97779B;
}

.program-card {
    margin-top: 5px;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    flex: 1 1 calc(25% - 16px); /* 4 cards per row */
    max-width: calc(25% - 16px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: transform 0.2s;
}

    .program-card:hover {
        transform: translateY(-5px);
        background-color: #007298;
        color:#fff;
    }

        .program-card:hover .date-overlay {
            background-color: #97779B;
            color: #fff;
        }

        .program-card:hover .program-card-footer {
            background-color: #007298;
        }

        .program-card-image {
            position: relative;
            height: 120px;
        }

    .program-card-image img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

.date-overlay {
    position: absolute;
    top: 95px;
    left: 8px;
    background-color: #007298;
    color: #fff;
    padding: 8px;
    border-radius: 0px;
    text-align: center;
    font-weight: bold;
    min-width: 52px;
}

.program-title-overlay {
    position: absolute;
    top: 0px;
    left: 0px;
    /*background-color: rgba(0, 114, 152, 0.8); *//* Set transparency with alpha (0.5 is 50% transparent) */
    background-color: rgba(0, 114, 152); /* No transparent */
    color: #fff;
    border-radius: 0px;
    text-align: center;
    font-weight: bold;
    width: 100%;
    height: 20%
}

    .program-title-overlay p {
        padding:0px;
        margin:3px 5px;
    }
    .date-month {
    font-size: 14px;
    text-transform: uppercase;
}

.date-day {
    font-size: 16px;
    font-weight: bold;
}

.date-year {
    font-size: 12px;
    font-weight: bold;
}

.program-card-details {
    padding: 10px;
    text-align: center;
}

.program-card-details p{
    padding: 0px;
    margin:0px;
}

.program-title {
    font-weight: bold;
    font-size: 1em;
    margin-bottom: 8px;
}

.program-details
 {
    font-size: 0.9em;
    margin-bottom: 8px;
}

.program-card-footer {
    padding: 0px 8px 10px 8px;
    background-color: #fff;
    text-align: center;
    /*border-top: 1px solid #ddd;*/
}

.register-button {
    background-color: #56c2b7;
    color: #003b4a;
    border: none;
    border-radius: 20px;
    padding: 8px 16px;
    cursor: pointer;
    font-size: 0.9em;
    font-weight: bold;
}


.filter-find-program-text {
    color: #97779B; /* Text color */
    font-size: 14px;
    margin-bottom:10px;
}

.filter-find-program {
    width: 215px;
    position: relative; /* This allows absolute positioning of child elements */
    display: inline-block; /* Ensures the container size is determined by the image */
}

    .filter-find-program img {
        width: 95%; /* You can control the image size here if needed */
        height: auto; /* Maintain the aspect ratio of the image */
    }

.request-program-overlay-text {
    position: absolute;
    top: 50%; /* Center the text vertically */
    left: 53%; /* Center the text horizontally */
    transform: translate(-50%, -50%); /* Perfect centering of the text */
    color: #97779B; /* Text color */
    font-size: 13px;
    text-align: center; /* Centers the text */
    width: 60%;
    cursor: pointer;
}

.filter-request-program {
    text-align:center;
    background-color: #97779B;
    border-style: solid;
    border-width: 1px;
    border-color: #F3808A;
    color: white;
    font-weight: bold;
    padding: 14px;
    border-radius: 10px;
    display: block; /* Ensure it's a block element for easier styling */
    word-wrap: break-word; /* Allow text to wrap inside the div */
    width: 90%; /* Ensure the div takes up the full width */
}

.new-home .filter-find-program a {
    text-decoration: none;
}

.new-home .filter-find-program a:hover {
    text-decoration: none;
    color:white;
}

/* Default navigation style */
.topnav {
    /*display: flex;*/
    justify-content: space-between;
    background-color: #fff;
    color: #fff;
    padding: 0px, 10px 20px;
    display: flex;
    align-items: center;
    flex-wrap: wrap; /* Allows content to wrap on smaller screens */
}

    .topnav a {
        color: #333;
        padding: 14px 16px;
        text-decoration: none;
        text-align: center;
    }

    /* Hide the menu links by default on small screens */
    /* For normal screen sizes (larger than 768px), keep links inline */
    .topnav a {
        display: inline-block;
        padding: 10px 20px;
    }


/* Hamburger button */
.hamburger {
    font-size: 30px;
    background: none;
    border: none;
    color: #F7D566; /* Set a color for visibility */
    display: none; /* Hidden by default */
}

.nav-pipe{
display:block;
}

.topFilter {
    display: none;
}


.bullet-items {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items horizontally */
    justify-content: center; /* Center items vertically */
    text-align: left; /* Center-align text inside the elements */
    width: 100%; /* Ensure it takes up full width of the parent container */
    margin: 0 auto; /* Optional: for centering in the parent container if it has limited width */
    margin-top:20px
}


/*.filter-bar {
    background-color: #97779B;*/ /* Purple color for the bar */
    /*padding: 10px;
    text-align: center;
    color: white;*/ /* White color for the text */
    /*font-weight: bold;
}*/

.filter-main {
    display: inline-block;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: bold;
    color: #97779B;
    border: solid 1px #97779B;
    border-radius: 20%;
    padding: 10px;
}

.no-upcoming-programs {
    margin: 0px 20px 20px 0px;
}
.about-programs h1 {
    color: #174a7c;
    font-size: 13px;
    font-weight: bold;
}
.compliance-message {
    margin: 5px 5px 5px 0px;
}

.search-button {
    margin-top:40px;
    text-align: center;
    background-color: #ffffff;
    border-style: solid;
    border-width: 2px;
    border-color: #97779B;
    color: #97779B;
    font-weight: bold;
    /*padding: 3px;*/
    border-radius: 20px;
    display: block; /* Ensure it's a block element for easier styling */
    word-wrap: break-word; /* Allow text to wrap inside the div */
    /*width: 30%;*/ /* Ensure the div takes up the full width */
}

    .search-button:focus, .search-button:active {
        border: 2px solid #97779B;
        outline: none; /* Removes default focus outline */
    }

.filter-button:focus, .filter-button:active {
    border: 2px solid #97779B;
    outline: none; /* Removes default focus outline */
}

.filter-button {
    text-align: center;
    background-color: #ffffff;
    border-style: solid;
    border-width: 2px;
    border-color: #97779B;
    color: #97779B;
    font-weight: bold;
    padding: 5px 20px 5px 20px;
    border-radius: 20px;
    display: block; /* Ensure it's a block element for easier styling */
    word-wrap: break-word; /* Allow text to wrap inside the div */
}



.new-home .top-row {
    display: flex;
    width: 100%;
}
.new-home .top-row-left-column {
    width: 75%;
    /*padding: 20px;*/
}

/* Filter section styles */
        .new-home .top-row .left-column .filter-bar {
            cursor: pointer; /* Make it look clickable */
            font-weight: bold;
            background-color: #97779B; /* Purple color for the bar */
            padding: 10px;
            text-align: center;
            color: white; /* White color for the text */
        }

.new-home .top-row-right-column {
    width: 25%;
    /*padding: 20px;*/
}

.filter-content.closed {
    display: none;
}

.filter-content.open {
    display: block;
    margin-bottom:10px
}

.divProgramcheckbox .checkbox-container {
    display: flex;
    gap: 20px; /* Adds space between the checkboxes */
    align-items: center; /* Vertically centers the checkbox and label */
}

    .divProgramcheckbox .checkbox-container div {
        display: flex;
        align-items: center; /* Ensures checkbox and label are aligned horizontally */
    }


.checkbox-container label {
    margin-bottom: 0;
}
.filter-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px; /* Adds spacing between elements */
}

    .filter-container > div {
        width: 48%; /* Adjust this to control the space between the sections */
        margin-top: 10px;
    }

.filter-location-container {
    display: flex;
    justify-content: space-between;
    width: 100%; /* Ensure it spans the full width */
    gap: 20px;
}


/* Responsive Styles */
@media (max-width: 768px) {
    .nav-pipe {
        display: none;
    }
    .hamburger {
        display: block; /* Show hamburger button */
        position: absolute; /* Position it at the top-right */
        right: 20px;
        top: 15px;
    }

    /* Menu links in the topnav, hidden by default on small screens */
    .topnav a {
        display: none; /* Hide links by default on small screens */
        text-align: left;
        padding: 10px;
        width: 100%; /* Make links full width on mobile */
        border-bottom: 1px solid #ddd;
    }

    /* Show the menu when responsive is active */
    .topnav.responsive a {
        display: block; /* Show the links when the menu is open */
    }

    /* Make sure the topnav takes full width on smaller screens */
    .topnav {
        width: 100%; /* Ensure the nav takes full width */
        /*position: absolute;*/
        top: 60px; /* Adjust the top position to accommodate the hamburger */
        left: 0;
    }
    .new-home .banner-container {
        display: none;
    }

    .new-home .topFilter {
        display: block;
        cursor: pointer;
        padding: 10px;
        background-color:#97779B;
        color: white;
        font-weight: bold;
    }

    /* Make the left-column responsive: hidden by default in smaller screens */
    /*.new-home .left-column {
        display: none;*/
        /* Ensure the left-column takes up full width when opened */
        /*width: 100%;*/
         /*padding: 20px 0;*/ /* Adjust padding for better spacing */
        /*transition: all 0.3s ease;
    }*/

        /* Show left-column when the filter is toggled open */
    /*.new-home .left-column.responsive {
        display: block;
        width: 100%;*/ /* Full width */
        /*padding: 20px;
        transition: all 0.3s ease;
    }*/

    /* Push the right column below when the left column is visible */
    .new-home .right-column {
        width: 100%; /* Full width */
        padding: 0px 10px; /* Adjust padding if needed */
    }

    .new-home .content {
        flex-direction: column; /* Stack columns vertically */
        width: calc(100% - 16px); /* Ensure content fits within the viewport */
    }


    .new-home .programs-row {
        display: grid; /* Use grid layout for better control */
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Responsive grid */
        gap: 16px;
        width: 100%; /* Ensure full width */
        margin: 0 auto;
    }



    .new-home .filter-request-program {
        font-size: 14px; /* Adjust the font size for smaller screens */
        padding: 10px; /* Ensure the padding is consistent */
        width:100%;
        text-align:center;
    }

    .new-home .divProgramcheckbox {
        min-height:auto;
    }

    .new-home .container {
        max-width: 100% !important; /* Ensure container fits viewport */
        padding: 0 12px; /* Further reduce padding */
    }
    .images-row {
        flex-direction: column; /* Stack the sections vertically on small screens */
        align-items: center; /* Center align all sections */
    }

    .left-image, .center-text, .right-image {
        flex: 1 1 100%; /* Make each section take up full width */
        text-align: center; /* Center align the content in each section */
        margin-bottom: 20px; /* Add space between stacked sections */
    }

        /* Optional: Adjust image sizes for smaller screens */
        .left-image img, .right-image img {
            width: 80%; /* Make images smaller on mobile */
            max-width: 200px; /* Optional: limit image size */
            margin: 0 auto; /* Center the images */
        }
    .capsule-item {
        width: 100%;
    }
    .new-home .row {
        flex-direction: row !important;
    }
/*    .filter-bar {
        display: none;
    }*/

    .program-card {
        flex: 1 1 calc(33.33% - 16px); /* Responsive three cards per row */
        max-width: none;
    }

    .new-home .top-row {
        display: block;
    }

    .new-home .top-row-right-column {
        width: 55%;
        margin-top: 10px;
        margin-bottom: 10px;
        padding: 0px;
    }

    .filter-container {
        flex-direction: column;
        align-items: flex-start;
    }

        .filter-container > div {
            width: 100%; /* Stacks them vertically on small screens */
        }

    .filter-location-container {
        flex-direction: column;
        align-items: stretch;
        gap: 0px;
    }

    .search-button {
        margin-top:10px;
        width: 30%;
    }

}

/* Mobile and Tablet Styles (up to 1024px) */
@media (max-width: 1024px) {
    .new-home .menu {
        flex-direction: column;
        text-align: center; /* Centers the content */
    }

    .nav-pipe {
        display: none;
    }

    .hamburger {
        display: block; /* Show hamburger button */
        position: absolute; /* Position it at the top-right */
        right: 20px;
        top: 15px;
    }

    /* Menu links in the topnav, hidden by default on small screens */
    .topnav a {
        display: none; /* Hide links by default on small screens */
        text-align: left;
        padding: 10px;
        width: 100%; /* Make links full width on mobile */
        border-bottom: 1px solid #ddd;
    }

    /* Show the menu when responsive is active */
    .topnav.responsive a {
        display: block; /* Show the links when the menu is open */
    }

    /* Make sure the topnav takes full width on smaller screens */
    .topnav {
        width: 100%; /* Ensure the nav takes full width */
        /*position: absolute;*/
        top: 60px; /* Adjust the top position to accommodate the hamburger */
        left: 0;
    }

    .new-home .banner-container {
        display: none;
    }

    .new-home .programs-row {
        display: grid; /* Use grid layout for better control */
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Responsive grid */
        gap: 16px;
        width: 100%; /* Ensure full width */
        margin: 0 auto;
    }


    .new-home .topFilter {
        display: block;
        cursor: pointer;
        padding: 10px;
        background-color: #97779B;
        color: #fff;
        font-weight: bold;
    }

    /* Make the left-column responsive: hidden by default in smaller screens */
    /*.new-home .left-column {
        display: none;
    }*/

        /* Show left-column when the filter is toggled open */
        /*.new-home .left-column.responsive {
            display: block;
            width: 100%;*/ /* Full width */
            /*padding: 0px 20px;
            transition: all 0.3s ease;
        }*/

    /* Push the right column below when the left column is visible */
    .new-home .right-column {
        width: 100%; /* Full width */
        padding: 0px 10px; /* Adjust padding if needed */
    }

    .new-home .filter-request-program {
        font-size: 14px; /* Adjust the font size for smaller screens */
        padding: 10px; /* Ensure the padding is consistent */
        width: 100%;
        text-align: center;
    }

    .new-home .divProgramcheckbox {
        min-height: auto;
    }

    .new-home .content {
        flex-direction: column; /* Stack columns vertically */
        width: calc(100% - 24px); /* Adjust width dynamically */
    }

    .new-home .container {
        max-width: 100% !important; /* Allow full viewport width on smaller screens */
        padding: 0 16px; /* Adjust padding */
    }
    .capsule-item {
        width: 100%;
    }
    .new-home .row {
        flex-direction: row !important;
    }
/*    .filter-bar {
        display: none;
    }*/
    .program-card {
        flex: 1 1 calc(33.33% - 16px); /* Responsive three cards per row */
        max-width: none;
    }

    .new-home .top-row {
        display: block;
    }

    .new-home .top-row-right-column {
        width: 55%;
        margin-top: 10px;
        margin-bottom: 10px;
        padding: 0px;
    }
    .filter-container {
        flex-direction: column;
        align-items: flex-start;
    }

        .filter-container > div {
            width: 100%; /* Stacks them vertically on small screens */
        }

    .filter-location-container {
        flex-direction: column;
        align-items: stretch;
        gap: 0px;
    }

    .search-button {
        margin-top: 10px;
        width: 30%;
    }
}

/* For very small mobile screens, adjust further (up to 480px) */
@media (max-width: 480px) {
    .new-home .menu {
        padding: 5px 15px;
    }

    .nav-pipe {
        display: none;
    }

    .hamburger {
        display: block; /* Show hamburger button */
        position: absolute; /* Position it at the top-right */
        right: 20px;
        top: 15px;
    }

    .topnav a {
        display: none; /* Hide links by default */
        text-align: left;
        padding: 10px;
        width: 100%; /* Full width links */
        border-bottom: 1px solid #ddd;
    }

    .topnav.responsive a {
        display: block; /* Show links when menu is open */
    }

    .topnav {
        width: 100%; /* Full width for nav */
        top: 60px;
        left: 0;
    }

    .new-home .banner-container {
        display: none;
    }

    .new-home .topFilter {
        display: block;
        cursor: pointer;
        padding: 10px;
        background-color: #56c2b7;
        color: white;
        font-weight: bold;
    }

/*    .new-home .left-column {
        display: none;
    }

        .new-home .left-column.responsive {
            display: block;
            width: 100%;
            background-color: #f4f4f4;
            padding: 20px;
            transition: all 0.3s ease;
        }*/

    .new-home .right-column {
        width: 100%; /* Full width */
        padding: 0px 10px; /* Adjust padding */
    }

    .new-home .filter-request-program {
        font-size: 14px; /* Adjust the font size for smaller screens */
        padding: 10px; /* Ensure the padding is consistent */
        width: 100%;
        text-align: center;
    }

    .new-home .divProgramcheckbox {
        min-height: auto;
    }

    .new-home .content {
        flex-direction: column; /* Stack columns vertically */
        width: calc(100% - 8px); /* Further adjust width to prevent overflow */
    }

    .new-home .programs-row {
        display: grid; /* Use grid layout for better control */
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); /* Responsive grid */
        gap: 16px;
        width: 100%; /* Ensure full width */
        margin: 0 auto;
    }

    .new-home .container {
        max-width: 100% !important; /* Full width for small screens */
        padding: 0 8px; /* Minimal padding */
    }

    .center-text {
        font-size: 14px; /* Adjust font size for very small screens */
    }

    .img-header-logo {
        width: 70%; /* Resize images even further for very small screens */
    }

    .capsule-item {
        width: 100%;
    }

    .new-home .row {
        flex-direction: row !important;
    }
/*    .filter-bar {
        display:none;
    }*/
    .program-card {
        flex: 1 1 calc(33.33% - 16px); /* Responsive three cards per row */
        max-width: none;
    }

    .new-home .top-row {
        display: block;
    }

    .new-home .top-row-right-column {
        width: 55%;
        margin-top: 10px;
        margin-bottom: 10px;
        padding: 0px;
    }
    .filter-container {
        flex-direction: column;
        align-items: flex-start;
    }

        .filter-container > div {
            width: 100%; /* Stacks them vertically on small screens */
        }

    .filter-location-container {
        flex-direction: column;
        align-items: stretch;
        gap: 0px;
    }

    .search-button {
        margin-top: 10px;
        width: 30%;
    }
}


